<template>
    <div>
        <v-dialog v-model="showDialogExport" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="oee-theme">
                    <v-btn icon dark @click="closePopup">
                        <v-icon>fa-times-circle</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title> {{isChinessLanguage ? '中国出仓库' : 'Xuất kho ngày'}} {{currentTime | display_date('DD-MM-YYYY')}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                <v-container grid-list-xl fluid>
                    <v-layout row wrap>
                        <v-flex lg12 md12 sm12 xs12 pt-6 pl-0>
                            <v-btn @click="upload_file"><v-icon>fa-file-excel-o</v-icon> Upload</v-btn>
                            <v-btn @click="export_file" class="ml-4"><v-icon>fa-file-excel-o</v-icon> Export</v-btn>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 pa-0>
                            <v-text-field :label="isChinessLanguage ? '入单号/入包裹编号 (enter)' : 'Nhập mã bill/mã bao (enter)'" v-model="exportCode" class="input-group--focused" @keyup.enter="onExportCode"></v-text-field>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 pa-0>
                            <v-data-table :headers="headers" :items="items" hide-default-footer disable-pagination :must-sort="true">
                            <template v-slot:item="{ item, index }">
                                <tr>
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.BigPackageCode }}</td>
                                    <td>{{ item.TransactionCode }}</td>
                                    <td class="text-center">
                                        <v-icon small @click="deleteItem(item.ID)">fa-trash-alt</v-icon>
                                    </td>
                                </tr>
                            </template>
                            </v-data-table>
                        </v-flex>
                    </v-layout>
                </v-container>
                </v-card-text>
                <v-card-actions>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <upload-file :dialogUpload="dialogImport" title="Import mã vận đơn" temp_file="kqc_xuatkhotq_ma_van_don_mau.xls" @closePopup="dialogImport=false" @importFile="importFile"></upload-file>
    </div>
</template>
<script>
import _ from "lodash";
import configs from "@/configs";
import upload_file from "@/components/popups/upload_file";
import { getCurrentTime, sendErrorNotice, playSound } from "@/commons/utils";

export default {
    components: {
        "upload-file": upload_file,
    },
    data() {
        return {
            viewName: "xuat-kho-kien-hang-tq",
            showDialogExport: true,
            exportCode: "",
            items: [],
            currentTime: getCurrentTime(),
            dialogImport: false,
            uuid: ''
        }
    },
    computed: {
        isChinessLanguage() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreTQ) == configs.ROLE_TYPE.StoreTQ && this.$store.state.authentication.user.ischinesslanguage;
        },
        headers() { 
            return [
                {
                    text: this.isChinessLanguage ? "No." : "STT",
                    value: "",
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: this.isChinessLanguage ? "包裹编号" : "Mã bao hàng",
                    value: "BigPackageCode",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: this.isChinessLanguage ? "单号" : "Mã bill",
                    value: "TransactionCode",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: this.isChinessLanguage ? "操作" : "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ];
        }
    },
    methods: {
        openExportDialog() {
            this.showDialogExport = true;
        },
        closePopup() {
            window.getApp.$emit("APP_NAVIGATE", "Dashboard");
        },
        create_uuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
                return v.toString(16);
            });
        },
        onExportCode() {
            const existed_item = _.find(this.items, (item) => {return item.BigPackageCode == this.exportCode || item.TransactionCode == this.exportCode});
            if(existed_item) {
                playSound('/vtb-error.mp3');
                sendErrorNotice('Mã bill/ mã bao đã được xuất kho.');
                return;
            }
            this.$store.dispatch("bigPackage/storeExportCode", {
                data: {
                    ExportCode: this.exportCode,
                    UUID: this.uuid
                }
            }).then((ret) => {
                if(ret.status == 200) {
                    this.items.push(ret.data.PackageType == 'BigPackage' ? {'BigPackageCode': this.exportCode, 'TransactionCode': ''} : {'BigPackageCode': '', 'TransactionCode': this.exportCode});
                    playSound('/vtb-success.mp3');
                }
                else { 
                    playSound('/vtb-error.mp3');
                }
                this.exportCode = '';
            });
        },
        export_file() {
            this.$store.dispatch("bigPackage/export", {
                filter: [{
                    column: "UUID",
                    value: this.uuid,
                    condition: "s_equal",
                }]
            });
        },
        upload_file() {
            this.dialogImport = true;
        },
        importFile(filelist) {
            for(const f of filelist) {
                const formData = new FormData();
                formData.append('excel_file', f);
                formData.append('filename', f.name);
                this.$store.dispatch("orderTransaction/upload_export_cn_store", formData);
            }
            this.dialogImport = false;
        },
    },
    mounted() {
        this.uuid = this.create_uuid();
        this.$root.$emit("REQUEST_ROOT_OPTION", {});
        this.$root.$on("REQUEST_CREATE_DATA", this.openExportDialog);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.openExportDialog);
    },
}
</script>